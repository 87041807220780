import React from 'react';
import { FaInstagram , FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-black text-gray-300">
      <div className="max-w-6xl mx-auto py-16 px-4 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
      <div className="flex flex-col">
        <h1 className="text-3xl font-bold text-white mb-4">Acroverse Architecture</h1>
        <p className="mb-6">Crafting Spaces, Inspiring Lives: Welcome to Acroverse Architecture.</p>
        <div className="flex justify-start items-center">
          <a href="https://www.instagram.com/acroverse.design/" target="_blank" rel="noopener noreferrer" className="mr-4">
            <FaInstagram size={30} />
          </a>
          <a href="https://wa.me/+919003111169" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp size={30} />
          </a>
        </div>
      </div>
      <div className="lg:col-span-2 lg:flex lg:justify-end lg:mt-6">
        <div>
          <h6 className="font-medium text-gray-400 mb-4">Design Solutions</h6>
          <ul className="text-sm">
            <li className="py-1">Architecture</li>
            <li className="py-1">Interior</li>
            <li className="py-1">Product</li>
            <li className="py-1">Furniture</li>
          </ul>
        </div>
      </div>
      </div>
      </div>
    </footer>
  );
};

export default Footer;
