import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {slides} from "../Data";
// Define an array of image paths or URLs for the slideshow

const transition = { type: 'tween', duration: 1, ease: 'easeOut' };

const Home = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Automatically change the image every 5 seconds
  useEffect(() => {
    const interval = setInterval(goToNextImage, 5000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative h-screen overflow-hidden">
      <AnimatePresence >
            <motion.img
          key={currentImageIndex}
          className="absolute w-full h-full object-cover"
          src={slides[currentImageIndex]}
          alt="Slideshow"
          initial={{ x: '100%', filter: 'blur(20px)' }} // Initial state with blur
          animate={{ x: 0, filter: 'blur(20px)' }} // Animate to normal state
          exit={{ x: '-100%', filter: 'blur(20px)' }} // Exit state with blur
          transition={transition}
        />
      </AnimatePresence>

      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center ">
        <div className="bg-white bg-opacity-80 rounded p-6">
          <div className="text-gray-800 sm:text-[1.25rem] mb-4 drop-shadow-lg">Welcome</div>
          <h1 className="text-[2rem] sm:text-[3rem] font-bold mb-4 text-gray-800 drop-shadow-lg">Acroverse Architecture</h1>
          <p className="opacity-80 text-[0.9rem] text-gray-800 drop-shadow-lg">
            A multidisciplinary architecture firm specializing in architecture, interior design, and product design. We bring together talent, creativity, and innovation to create extraordinary spaces that inspire and captivate. Explore our portfolio and join us on a remarkable journey of design excellence.
          </p>
          <p className="text-red-600 mt-4">Website is under maintenance</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
