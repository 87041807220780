import React, { useState } from "react";
import { HiMenuAlt3, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import { ReactComponent as ARLogo } from "../asset/logo.svg";
import { navLinks } from "../Data";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  const toggleMenu = () => {
    setToggle(!toggle);
  };

  return (
    <div className="fixed top-0 left-0 right-0 z-20 bg-white shadow-md">
      <div className="container mx-auto flex items-center justify-between py-4 px-4">
        <div className="flex items-center">
          <div className="w-12 h-12 flex justify-center items-center">
          <a href="/" className="w-12 h-12 flex justify-center items-center">
              <motion.div
                          whileHover={{ scale: 1.1, rotate: 360 }}
                          className="w-12 h-12 flex justify-center items-center"
                        >
            <ARLogo className="w-auto h-auto" />
          </motion.div>
          </a>
          </div>
          <a href="/" >
          <p className="text-xl ml-2">
            Acroverse 
            {/* <span className="opacity-80">Studio</span> */}
          </p>
          </a>
        </div>

        <div className="md:flex hidden items-center space-x-6">
          {/* Navigation Links for Desktop */}
          <div className="space-x-6">
            {navLinks.map((navLink) => (
              <a key={navLink.id} href={`#${navLink.href}`} className="text-sm transition duration-150 border-b-2 border-transparent hover:border-gray-500">
                {navLink.text}
              </a>
            ))}
          </div>
        </div>

        <HiMenuAlt3
          className="cursor-pointer text-3xl md:hidden"
          onClick={toggleMenu}
        />
      </div>

      {toggle && (
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
          transition={{ type: "spring", damping: 20, stiffness: 100 }}
          className="fixed top-0 right-0 h-screen bg-black text-white w-64"
        >
          <div className="flex flex-col items-center space-y-6 mt-14">
            {/* Navigation Links for Mobile */}
            {navLinks.map((navLink) => (
              <a
                key={navLink.id}
                href={`#${navLink.href}`}
                className="text-sm"
                onClick={toggleMenu}
              >
                {navLink.text}
              </a>
            ))}
          </div>
          <HiX
            className="absolute right-4 text-3xl top-4 cursor-pointer"
            onClick={toggleMenu}
          />
        </motion.div>
      )}
    </div>
  );
};

export default Navbar;
