import React,  { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Navbar from "./components/Navbar";
import Loading from './components/Loading';
import Footer from './components/Footer';
import Contact from './components/Contact';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading for 2 seconds, replace this with your actual loading logic
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Router>
            {loading ? (
        <Loading />
      ) : (
        <div className="font-Poppins bg-ghostWhite">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            
          </Routes>
          <Contact />
          <Footer />
        </div>
      )}
    </Router>
  );
}

export default App;
