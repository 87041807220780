import React from 'react';
import { motion } from 'framer-motion';

const Loading = () => {
  return (
    <div className="bg-white min-h-screen flex items-center justify-center">
      <motion.div
        className="w-1/4" // Adjust the width as needed for responsiveness
        initial={{ opacity: 0, scale: 0.2, rotate: 0 }}
        animate={{ opacity: 1, scale: 1, rotate: 360 }}
        exit={{ opacity: 0, scale: 0.5 }}
        transition={{ duration: 1 }}
      >
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%" // Make the SVG responsive
          height="100%" // Make the SVG responsive
          viewBox="0 0 43819.14 37948.48"
          whileHover={{ scale: 1.1, transition: { duration: 0.3 } }}
        >
          <g id="Layer_x0020_1">
            <polygon
              className="fil0"
              points="8975.31,30734.01 4165.28,30734.01 21909.57,0 24314.58,4165.59 21909.57,8331.2"
            />
            <g>
              <path
                className="fil0"
                d="M8975.31 30734.01l-4839.48 2.14 4839.48 -2.14zm27628.79 3048.87l-29389.06 0 29389.06 0zm0 0l-34199.09 0 -2405.01 4165.6 43819.14 0 -17527.82 -30359.06 -2404.82 4165.93 12717.6 22027.53z"
              />
            </g>
          </g>
        </motion.svg>
        <h1 className="text-[1rem]  lg:text-[3rem] md:text-[1.3rem] sm:text-[1.1rem] xs:text-[0.75rem]  justify-center font-Poppins mb-4 text-Black ">Acroverse Studio</h1>
      </motion.div>
    </div>
  );
};

export default Loading;
